import PrimaryButton from 'DesignComponents/Atoms/Buttons/PrimaryButton';
import { useEffect, useRef } from 'react';
import Button from 'Shared/Components/Buttons/Button';
import {
  ModalFooterActionAreaStyled,
  ModalFooterActionStyled,
  ModalFooterStyled,
  ModalFooterProps,
} from 'DesignComponents/Organisms/Modal/ModalFooter';
import { useTranslationData } from 'Shared/Providers/TranslationProvider';
import { useUiState } from 'Shared/Providers/UiState/UiStateProvider';

const TermsAndConditionsModalFooter = ({
  getModalFooterHeight,
  displayModal,
  modalFooterContent,
}: ModalFooterProps) => {
  const ref = useRef<HTMLInputElement>(null);

  const { toggleModal } = useUiState();
  const {
    commonLabels: { close },
  } = useTranslationData();

  useEffect(() => {
    if (displayModal) {
      setTimeout(() => {
        if (getModalFooterHeight)
          getModalFooterHeight(ref.current?.offsetHeight);
      }, 310); // Wait for transition to finish then get the height from footer
    }
  }, [displayModal]);

  if (!modalFooterContent) return null;

  return (
    <ModalFooterStyled ref={ref} footerLayout={modalFooterContent.footerLayout}>
      <ModalFooterActionAreaStyled
        footerLayout={modalFooterContent.footerLayout}
      >
        <ModalFooterActionStyled footerLayout={modalFooterContent.footerLayout}>
          <Button<typeof PrimaryButton>
            element={'PrimaryButton'}
            props={{
              text: close,
              color: 'Regular',
            }}
            onClick={() => {
              toggleModal(false);
            }}
          />
        </ModalFooterActionStyled>
      </ModalFooterActionAreaStyled>
    </ModalFooterStyled>
  );
};

export default TermsAndConditionsModalFooter;
